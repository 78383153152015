<template>
  <div v-loading="loading" class="container">
    <div class="filter-box card">
      <filter-component
        :options="filterOptions"
        :formatter-data="search"
        @change="getFilterValue"
      />
      <el-button type="primary" @click="create">创建统计</el-button>
    </div>
    <div class="card card-list">
      <stat-list
        v-if="list.length"
        :list="list"
        @getData="getData"
        @publish="publish"
      />
      <div v-if="list.length" class="page-list">
        <el-pagination
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="total, sizes, prev,pager, next, jumper"
          :total="total"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
        />
      </div>
      <no-data v-else style="margin: 100px 0"></no-data>
    </div>
    <el-dialog
      title="创建统计"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="form"
        label-position="right"
        label-width="100px"
        :rules="rules"
        :model="form"
      >
        <el-form-item label="统计名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入统计名称"></el-input>
        </el-form-item>
        <el-form-item label="学段筛选：" prop="level">
          <el-select
            v-model="form.level"
            placeholder="请选择"
            @change="levelChange"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入学年份：" prop="year">
          <el-select
            v-model="form.year"
            placeholder="请选择"
            @change="yearChagne"
          >
            <el-option
              v-for="item in yearOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择考试：" prop="examId">
          <el-select
            v-model="form.examId"
            placeholder="请选择"
            @change="examChange"
          >
            <el-option
              v-for="item in examList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统计考生：">
          <el-select v-model="form.studentSubject" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option
              v-for="item in subjectList"
              :key="item.subjectId"
              :label="item.name"
              :value="item.subjectId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统计科目：" prop="checkList">
          <el-checkbox-group v-model="form.checkList" @change="checkBoxChange">
            <el-checkbox
              v-for="(item, index) in subjectList"
              :key="index"
              :label="item.subjectId"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button v-loading="submitLoading" type="primary" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <result-model
      ref="resultModelView"
      :list-obj="resultData"
      @getNewList="getNewList"
    ></result-model>
  </div>
</template>

<script>
import filterComponent from "./components/filter.vue";
import statList from "./components/statList.vue";
import resultModel from "../exam/component/resultModel.vue";
import {
  levelOptions,
  examTypeOptions,
  examModeOptions,
} from "@/core/util/constdata";
// import {
//   levelOptions,
//   examTypeOptions,
//   examModeOptions,
// } from "@/core/util/constdata";
import { getGradeList } from "@/core/api/school/schoolgradeclass";
import {
  getStatisticsList,
  getexamlistbyyear,
  getexamsubjectlist,
  createcustomstat,
} from "@/core/api/academic/statistics";
import { getRoleSearchParams } from "@/core/api/academic/common";
import { getSubjectList } from "@/core/util/util";
import { mapState } from "vuex";

export default {
  name: "AcademicStatistics",
  components: {
    filterComponent,
    statList,
    resultModel,
  },
  data() {
    let subjectList = getSubjectList();
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearData = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    let yearList = yearData.reverse();
    return {
      search: {
        current: 1,
        size: 50,
      },
      checkList: [],
      loading: true,
      form: {
        studentSubject: 0,
        name: "",
        checkList: [],
      },
      rules: {
        examId: [{ required: true, message: "请选择考试", trigger: "blur" }],
        name: [{ required: true, message: "请输入统计名称", trigger: "blur" }],
        checkList: [
          { required: true, message: "请选择统计科目", trigger: "change" },
        ],
        level: [{ required: true, message: "请选择学段", trigger: "change" }],
        year: [
          { required: true, message: "请选择入学年份", trigger: "change" },
        ],
      },
      dialogVisible: false,
      total: 0,
      yearList: [...yearList],
      schooleYearOptions: [],
      levelOptions: levelOptions(),
      showResult: false,
      filterOptions: [
        {
          label: "学段：",
          field: "examLevel",
          options: [],
          type: "select",
          reset: "year",
        },
        {
          label: "年份：",
          field: "examYear",
          options: [...yearList],
          type: "select",
          labelKey: "label",
          valueKey: "value",
        },
        {
          label: "模式：",
          field: "examMode",
          options: examModeOptions,
          type: "select",
        },
        {
          label: "类型：",
          field: "examType",
          options: examTypeOptions,
          type: "select",
        },
        {
          label: "科目：",
          field: "examSubjectId",
          options: [...subjectList],
          type: "select",
          labelKey: "subjectName",
          valueKey: "subjectId",
        },
        {
          label: "时间：",
          field: "examDateYear",
          options: [...yearList],
          type: "select",
          labelKey: "label",
          valueKey: "value",
        },

        {
          label: "状态：",
          field: "statusStatistic",
          options: [
            {
              label: "未统计",
              value: 0,
            },
            {
              label: "已统计",
              value: 1,
            },
          ],
          type: "select",
        },
        {
          field: "examName",
          type: "input",
        },
      ],
      list: [],
      yearMiddleOptions: [], //初中入学年份
      yearHignOptions: [], //高中入学年份
      yearOptions: [...yearList],
      examList: [],
      subjectList: [],
      resultData: [],
      userInfo: {},
      submitLoading: false,
      resultModelKey: 1,
    };
  },
  computed: {
    ...mapState({
      roleId: (state) => state.report.roleId,
    }),
  },
  mounted() {
    const searchObj = sessionStorage.getItem(this.$route.path);
    if (searchObj) {
      this.search = { ...JSON.parse(searchObj) };
    }
    this.getSchoolYearList();
    this.getData();
    this.getRoleSearchParamsData();
    const userMsg = JSON.parse(localStorage.getItem("jzjx-user-message"));
    this.userInfo = userMsg;
    const userInfo = JSON.parse(localStorage.getItem("jzjx-userInfo")).content;
    if (this.roleId == 6 || userInfo.schoolType == 2) {
      this.filterOptions.splice(3, 1);
    }
  },
  methods: {
    getNewList() {
      this.getData();
    },
    getRoleSearchParamsData() {
      getRoleSearchParams().then((res) => {
        const { levels } = res.data.data;
        this.filterOptions[0].options = this.levelOptions.filter((item) =>
          levels.includes(Number(item.value))
        );
        // this.filterOptions[3].options = [...subjects];
      });
    },
    publish(item) {
      // this.resultModelKey = new Date().getTime();
      this.showResult = true;

      let subjectStr = item.subjectStr.split("，");
      let subjectIds = item.subjectIds.split(",");
      item.schoolId = this.userInfo.content.schoolId;
      item.paperList = subjectIds.map((it, ind) => {
        return {
          subjectId: Number(it),
          subjectName: subjectStr[ind],
          examId: item.examId,
        };
      });
      this.resultData = item;
      this.$refs.resultModelView.onload(item);
    },
    create() {
      this.dialogVisible = true;
      this.$refs.form.clearValidate();
    },
    checkBoxChange() {
      this.form.subjectIds = this.form.checkList.join(",");
    },
    async yearChagne() {
      const res = await getexamlistbyyear({ year: this.form.year });
      const { data } = res.data;
      this.form.examId = null;
      this.examList = data;
    },
    levelChange() {
      // this.yearOptions =
      //   (val == 2 && this.yearMiddleOptions) ||
      //   (val == 3 && this.yearHignOptions);
    },
    async examChange(val) {
      const { name } = this.examList.find((item) => item.id === val);
      this.form.name = name;
      const res = await getexamsubjectlist({ examId: val });
      this.subjectList = res.data.data;
      this.$refs.form.clearValidate();
    },
    async getData() {
      this.loading = true;
      const {
        data: {
          data: { total, records },
        },
      } = await getStatisticsList(this.search);
      this.loading = false;
      this.total = total;
      this.list = [...records];
    },
    getFilterValue(val) {
      this.search = { current: 1, size: 10, ...val };
      this.getData();
      sessionStorage.setItem(this.$route.path, JSON.stringify(this.search));
    },
    async getSchoolYearList() {
      const {
        data: { data: list },
      } = await getGradeList();
      this.yearMiddleOptions = list.filter((item) => item.level === 2);
      this.yearHignOptions = list.filter((item) => item.level === 3);
    },
    sizeChangeHandle(val) {
      this.search.size = val;
      this.getData();
    },
    currentChangeHandle(val) {
      this.search.current = val;
      this.getData();
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          try {
            await createcustomstat(this.form);
            this.search.page = 1;
            this.handleClose();
            this.getData();
            this.submitLoading = false;
          } catch {
            this.getData();
            this.submitLoading = false;
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.yearOptions = [];
      this.examList = [];
      this.subjectList = [];
      this.form = {
        studentSubject: 0,
        name: "",
        checkList: [],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .filter-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.el-button {
  height: 32px;
  // line-height: 32px;
  font-size: 14px;
}
::v-deep .el-dialog {
  max-width: 816px;
  .el-dialog__header {
    padding: 24px 32px 0;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
    }
    .el-dialog__headerbtn {
      // right: 29px;
      // top: 29px;
    }
  }
  .el-dialog__body {
    padding: 24px 32px;
    .el-form {
      &:last-child {
        // border-bottom: 1px solid #e0e0e0;
      }
    }

    .form-item-box {
      // border-top: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .checkbox-item {
        width: 100%;
        // padding-left: 100px;
        margin-bottom: 8px;
      }
      .el-date-editor.el-input {
        width: 100%;
      }
      .item-box-btn {
        display: flex;
        justify-content: space-between;
        p {
          color: $primary-color;
          font-size: 14px;
          margin-right: 8px;
          margin-left: 8px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .two-box {
      .el-form-item {
        width: 49%;
      }
    }
    .el-form-item {
      padding: 8px 0;
      margin-bottom: 0;
    }
  }
  .el-form-item__label {
    text-align: left;
  }
}
::v-deep .el-form-item__content {
  .el-select {
    width: 100%;
    border-color: #dee1e7;
    background-color: #fff;
  }
  ::v-deep .el-input {
    input {
      background-color: #f5f5f5;
      border-color: #f5f5f5;
    }
  }
}
::v-deep .el-dialog__wrapper {
  transition-duration: 0.3s;
}

::v-deep .el-dialog .el-dialog__header {
  padding: 18px !important;
}
</style>
