<template>
  <div class="statList">
    <div v-for="(item, index) in list" :key="index" class="statList-item">
      <div class="statList-item-header">
        <div class="title">
          <div :class="[item.securityLevel === 1 ? 'security1' : 'security2']">
            {{ getSecurityName(item.securityLevel) }}
          </div>
          <div v-if="getStatStatus(item.id) == 0" class="not_counted">
            未统计
          </div>
          <div v-if="getStatStatus(item.id) == 1" class="counted">已统计</div>
          <div v-if="getStatStatus(item.id) == 2" class="counting">处理中</div>
          <div v-if="getStatStatus(item.id) == 3" class="fail">统计失败</div>
          <div v-if="item.ruleId > 0" class="tag">新高考</div>
          <div v-if="item.type === 1" class="tag examType1">考试统计</div>
          <div v-if="item.type === 2" class="tag examType2">单科统计</div>
          <div v-if="item.type === 3" class="tag examType3">自定义统计</div>
          <div class="tag year">
            <span v-if="item.level === 1">小</span>
            <span v-if="item.level === 2">初</span>
            <span v-if="item.level === 3">高</span>
            <span v-if="item.level === 4">大</span>
            {{ item.year }}级
          </div>
          <div class="title-content">{{ item.name }}</div>
          <div v-if="getStatStatus(item.id) == 2" class="ft-red">
            {{ getStatJobMessage(item.id) }}
          </div>
          <div
            v-if="item.statTime && getStatStatus(item.id) != 0"
            class="updateTime"
          >
            （更新数据时间：{{ item.statTime }}）
          </div>
          <div
            v-if="
              getstatusPublishTeacher(item.id) == 1 &&
              getStatStatus(item.id) != 0
            "
            class="pulish-text"
          >
            已发布给教师
          </div>
          <div
            v-if="
              getStatPublishStudent(item.id) == 1 && getStatStatus(item.id) != 0
            "
            class="pulish-text"
          >
            已发布给学生
          </div>
        </div>
        <div v-if="item.examDate" class="exam-time">
          考试日期：{{ item.examDate }}
        </div>
      </div>
      <div class="operate">
        <div class="subject">统计科目：{{ item.subjectStr }}</div>
        <i
          v-if="item.type == 3"
          class="el-icon-delete"
          @click="deleteStatItem(item)"
        ></i>
      </div>
      <div v-if="getStatStatus(item.id) != 2" class="statList-item-content">
        <div class="content-operation">
          <div v-if="item.examType != 1" class="button">
            <el-button
              type="text"
              @click="routerTo(`/exam/exampaper?id=${item.examId}`)"
              >试题管理</el-button
            >
          </div>
          <div
            v-if="getStatStatus(item.id) == 0 || getStatStatus(item.id) == 3"
            class="button"
          >
            <el-button
              type="text"
              @click="
                routerName(
                  item.examType == 1 ? 'unionExamNewResult' : 'ExamNewResult',
                  item
                )
              "
              >调整成绩</el-button
            >
          </div>
          <div
            v-if="getStatStatus(item.id) == 0 || getStatStatus(item.id) == 3"
            class="button"
          >
            <el-button
              type="text"
              @click="routerTo('/academic/statistics/merge', item)"
              >合并科目</el-button
            >
          </div>
          <div
            v-if="getStatStatus(item.id) == 0 || getStatStatus(item.id) == 3"
            class="button"
          >
            <el-button
              type="text"
              @click="routerTo('/academic/statistics/split', item)"
              >拆分科目</el-button
            >
          </div>
          <div
            v-if="getStatStatus(item.id) == 0 || getStatStatus(item.id) == 3"
            class="button"
          >
            <el-button
              type="text"
              @click="routerTo('/academic/statistics/setting', item)"
              >统计设置</el-button
            >
          </div>
          <div v-if="getStatStatus(item.id) === 1" class="button">
            <el-button type="text" @click="changeStatusView(item)">{{
              item.statusView === 0 ? "已关闭统计查看" : "已开放统计查看"
            }}</el-button>
          </div>
          <div v-if="getStatStatus(item.id) === 1" class="button">
            <el-button type="text" @click="changeAnnotation(item)"
              >批注信息设置</el-button
            >
          </div>
          <div v-if="getStatStatus(item.id) === 1" class="button">
            <el-button
              type="text"
              @click="routerTo('/academic/statistics/staticsSetting', item)"
              >查看统计参数设置</el-button
            >
          </div>
          <div v-if="item.examType == 1" class="button">
            <rankSetting :item="item" @ok="handleOk" />
          </div>
        </div>
        <div class="item-operation">
          <el-button
            v-if="getStatStatus(item.id) == 1"
            type="primary"
            plain
            @click="toReportSurface(item)"
            >查看统计报表</el-button
          >

          <el-button
            v-if="getStatStatus(item.id) == 1 && getExamTypeStatus(item)"
            type="primary"
            plain
            @click="toReport(item)"
            >查看统计报告</el-button
          >
          <!-- 高风险禁用发布成绩 -->
          <el-button
            v-if="getStatStatus(item.id) == 1"
            type="primary"
            plain
            :disabled="item.securityLevel == 2"
            @click="publish(item)"
            >发布成绩</el-button
          >
          <el-button
            v-if="getStatStatus(item.id) == 1"
            type="success"
            plain
            @click="statStart(item)"
            >开启统计</el-button
          >
          <el-button
            v-if="getStatStatus(item.id) == 0 || getStatStatus(item.id) == 3"
            type="primary"
            plain
            @click="routerTo('/academic/statistics/createStat', item)"
            >生成统计
          </el-button>
        </div>
      </div>
      <div v-else class="progress">
        <el-progress
          :percentage="getStatProgress(item.id)"
          :stroke-width="10"
          :show-text="false"
        ></el-progress>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <template v-if="title === '筛选联考学校'">
        <div class="schools">
          <a-space style="margin-bottom: 18px">
            <a-input v-model="schoolName" placeholder="查询学校名称">
              <a-icon slot="suffix" type="search" />
            </a-input>
            <a-button type="primary" @click="schoolNameInput">搜索</a-button>
          </a-space>
          <div v-if="originSchoolList.length" class="schoolList">
            <div
              v-for="(item, index) in schoolList"
              :key="index"
              class="school"
              @click="goDetail(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <no-data v-else message="当前账号没有查看当前联考的权限"></no-data>
        </div>
      </template>
      <div v-if="title === '批注信息设置'">
        <el-checkbox v-model="annotationForm.sheetPrintScore">分值</el-checkbox>
        <el-checkbox v-model="annotationForm.sheetPrintQuestionScore"
          >小题分值</el-checkbox
        >
        <el-checkbox v-model="annotationForm.sheetPrintTeacher"
          >阅卷老师</el-checkbox
        >
        <el-checkbox v-model="annotationForm.sheetPrintNotes"
          >批注信息</el-checkbox
        >
        <el-checkbox v-model="annotationForm.sheetPrintObjectNotes"
          >客观题批注</el-checkbox
        >
      </div>
      <div v-if="title === '提示'" class="startStat">
        <div class="startStat-text">
          开启统计设置后，为保证数据准确性及一致性
        </div>
        <div class="startStat-warning">
          学生端：学生成绩不自动取消，如需要可手动
        </div>
        <div>确定开启？</div>
      </div>
      <span v-if="title !== '筛选联考学校'" slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="createDialogVisible"
      width="30%"
      :before-close="createHandleClose"
    >
      <div v-if="statCreateStatus == 0" class="tip-box">
        <i class="el-icon-warning fail"></i>
        <div>
          {{ creatingData.name
          }}<span class="ft-red">统计失败</span>！您可尝试重新生成统计！
        </div>
      </div>
      <div v-if="statCreateStatus == 1" class="tip-box">
        <i class="el-icon-success success"></i>
        <div>
          {{ creatingData.name }}<span class="ft-green">统计成功</span>！
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="createDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  upDateStatusViews,
  changestatsheetprintsetting,
  openstat,
  deleteReport,
  getstatprogress,
} from "@/core/api/academic/statistics";
import { getStore } from "@/core/util/store";
import { getstatschooloption } from "@/core/api/academic/common";
import { examSecurityLevelOptions } from "@/core/util/constdata";
import rankSetting from "./rankSetting.vue";
export default {
  name: "StatList",
  components: {
    rankSetting,
  },
  beforeRouteLeave() {
    clearInterval(this.timer);
  },
  props: {
    list: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      dialogVisible: false,
      title: "",
      annotationForm: {},
      annForm: {},
      btnLoading: false,
      itemId: "",
      timer: null,
      statProgressList: [],
      createStatFlag: false,
      createDialogVisible: false,
      statCreateStatus: null, // 1 成功  0 失败
      creatingData: null,
      schoolList: [],
      schoolName: undefined,
      originSchoolList: [],
      currentStat: null,
    };
  },
  watch: {},
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  mounted() {
    this.getstatprogressData();
    clearInterval(this.timer);
    this.timer = null;
    this.timer = setInterval(() => {
      // console.log(this.$route);
      this.getstatprogressData();
    }, 3000);
  },
  methods: {
    handleOk() {
      this.$emit("getData");
    },
    getSecurityName(level) {
      if (level) {
        const data = examSecurityLevelOptions.find(
          (item) => item.value === level
        );
        if (data) {
          return data.label;
        }
      }
    },
    createHandleClose() {
      this.createDialogVisible = false;
    },
    getStatStatus(id) {
      if (this.statProgressList.length) {
        const data = this.statProgressList.find((item) => item.id == id);
        if (data) return data.statusStat;
      }
    },
    getExamTypeStatus(item) {
      if (item.examType == 1) {
        return true;
      }
      let { schoolVersion } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      if (schoolVersion == 1) {
        return false;
      }
      // if (item.examType == 1) {
      //   if (item.isUnionSubjectUser == 0) {
      //     return true;
      //   }
      //   return false;
      // }

      return true;
    },
    getStatJobMessage(id) {
      if (this.statProgressList.length) {
        const data = this.statProgressList.find((item) => item.id == id);
        return data.jobMessage;
      }
    },
    getStatPublishStudent(id) {
      if (id) {
        const data = this.statProgressList.find((item) => item.id == id);
        if (data) {
          return data.statusPublishStudent;
        }
      }
    },
    getstatusPublishTeacher(id) {
      if (id) {
        const data = this.statProgressList.find((item) => item.id == id);
        if (data) {
          return data.statusPublishTeacher;
        }
      }
    },
    getStatProgress(id) {
      if (this.statProgressList.length && id) {
        const data = this.statProgressList.find((item) => item.id == id);
        return data.statProgress;
      }
    },
    async getstatprogressData() {
      const res = await getstatprogress({
        ids: this.list.map((item) => item.id).toString(),
      });
      this.statProgressList = [...res.data.data];
      if (this.$route.query.createStatId) {
        this.createStatFlag = true;
        this.creatingData = this.list.find(
          (item) => item.id == this.$route.query.createStatId
        );
      }
      if (this.createStatFlag) {
        const itemData = res.data.data.find(
          (item) => item.id == this.$route.query.createStatId
        );
        const { statProgress, statusStat } = itemData;
        if (statProgress == 100 && statusStat == 1) {
          this.createDialogVisible = true;
          this.statCreateStatus = 1;
          this.createStatFlag = false;
          this.$router.replace("/academic/statistics");
        } else if (statusStat == 3) {
          this.createDialogVisible = true;
          this.statCreateStatus = 0;
          this.createStatFlag = false;
          this.$router.replace("/academic/statistics");
        }
      }
    },

    deleteStatItem(item) {
      this.$confirm("是否永久删除该统计", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteReport({ id: item.id }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$emit("getData");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toReportSurface(item) {
      if (item.examType === 1) {
        const { href } = this.$router.resolve({
          path: "/union/sheet",
          query: {
            id: item.id,
            name: item.name,
            examType: item.examType,
            type: item.type,
            level: item.level,
            examDate: item.examDate,
            isUnionSubjectUser: item.isUnionSubjectUser,
            scoreType: item.scoreType,
            examId: item.examId,
            ruleId: item.ruleId,
            year: item.year,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
      } else {
        this.$store.dispatch("report/getRoleTypeArr", item.id).then((res) => {
          this.$router.push({
            path: "/academic/once",
            query: {
              statId: item.id,
              examDate: item.examDate,
              examType: item.examType,
              ruleId: item.ruleId,
              type: item.type,
              isUnionSubjectUser: item.isUnionSubjectUser,
              level: item.level,
              year: item.year,
              examId: item.examId,
            },
          });
          sessionStorage.setItem("accountRole", JSON.stringify(res));
        });
      }
    },
    schoolNameInput() {
      if (this.schoolName) {
        this.schoolList = this.originSchoolList.filter(
          (item) => item.name.indexOf(this.schoolName) != -1
        );
      } else {
        this.schoolList = [...this.originSchoolList];
      }
    },
    goDetail(item) {
      this.$store
        .dispatch("report/getRoleTypeArr", this.currentStat.id)
        .then((res) => {
          this.$router.push({
            name: "/academic/reportStat/reportDetail",
            query: {
              id: this.currentStat.id,
              level: this.currentStat.level,
              examId: this.currentStat.examId,
              ruleId: this.currentStat.ruleId,
              schoolId: this.currentStat.examType == 1 ? item.id : null,
              schoolName: item.name,
              scoreType: this.currentStat.scoreType,
              isSchoolDisplayReportStudentRank:
                this.currentStat.isSchoolDisplayReportStudentRank,

              isUnionSubjectUser: this.currentStat.isUnionSubjectUser,
              examType: this.currentStat.examType,
            },
          });
          sessionStorage.setItem("accountRole", JSON.stringify(res));
        });
    },
    async toReport(item) {
      let { roleId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;

      this.currentStat = { ...item };
      if (item.examType == 1) {
        let { schoolType } = JSON.parse(
          localStorage.getItem("jzjx-userInfo")
        ).content;
        if (roleId == 6 || ([1, 5].includes(roleId) && schoolType == 2)) {
          this.title = "筛选联考学校";
          this.dialogVisible = true;
          const res = await getstatschooloption({ id: item.id });
          this.schoolList = res.data.data;
          this.originSchoolList = res.data.data;
          this.dialogVisible = true;
        } else if (roleId == 4) {
          this.$router.push({
            path: "/academic/reportStat/studentReport",
            query: {
              name: item.name,
              year: item.year,
              examDate: item.examDate,
              studentName: this.userInfo.content.name,
              subjectId: 0,
              studentId: this.userInfo.content.studentId,
              type: item.type,
              id: item.id,
              level: item.level,
              examId: item.examId,
              isUnionSubjectUser: item.isUnionSubjectUser,
              scoreType: item.scoreType,
              examType: item.examType,
              isSchoolDisplayReportStudentRank:
                item.isSchoolDisplayReportStudentRank,
              ruleId: item.ruleId,
              schoolId:
                item.examType == 1 ? getStore({ name: "school_id" }) : null,
            },
          });
        } else {
          this.$store.dispatch("report/getRoleTypeArr", item.id).then((res) => {
            this.routerBase64To("/academic/reportStat/reportDetail", item);
            sessionStorage.setItem("accountRole", JSON.stringify(res));
          });
        }
      } else {
        this.$store.dispatch("report/getRoleTypeArr", item.id).then((res) => {
          this.routerBase64To("/academic/reportStat/reportDetail", item);
          sessionStorage.setItem("accountRole", JSON.stringify(res));
        });
      }
    },
    publish(item) {
      if (item.examType == 1) {
        this.$router.push({
          path: "/academic/union/publish",
          query: {
            id: item.id,
            examType: item.examType,
            year: item.year,
            ruleId: item.ruleId,
            name: item.name,
            baseType: item.baseType,
            examId: item.examId,
          },
        });
      } else {
        if (item.securityLevel == 2) {
          this.$message({
            showClose: false,
            message: "本次考试为高风险考试，禁止发布。",
            type: "error",
          });
        } else {
          this.$emit("publish", item);
        }
      }
    },
    async changeStatusView(item) {
      await upDateStatusViews({
        status: item.statusView === 1 ? 0 : 1,
        id: item.id,
      });
      this.$emit("getData");
    },
    routerTo(url, item) {
      if (item) {
        `${url}?name=${item.name}&id=${item.id}`;
        this.$router.push({
          path: url,
          query: {
            name: item.name,
            id: item.id,
            examType: item.examType,
            type: item.type,
            dataRelease: item.dataRelease,
            securityLevel: item.securityLevel,
            examId: item.examId,
            level: item.level,
            year: item.year,
            ruleId: item.ruleId,
          },
        });
      } else {
        this.$router.push(url);
      }
    },
    routerBase64To(routerName, item) {
      this.$router.push({
        name: routerName,
        query: {
          id: item.id,
          level: item.level,
          examId: item.examId,
          ruleId: item.ruleId,
          isSchoolDisplayReportStudentRank:
            item.isSchoolDisplayReportStudentRank,
          isUnionSubjectUser: item.isUnionSubjectUser,
          schoolId:
            item.examType == 1
              ? JSON.parse(localStorage.getItem("jzjx-school_id")).content
              : null,
          schoolName: item.schoolName,
          examType: item.examType,
        },
      });
    },
    changeAnnotation({
      sheetPrintNotes,
      sheetPrintObjectNotes,
      sheetPrintScore,
      sheetPrintTeacher,
      sheetPrintQuestionScore,
      id,
    }) {
      this.dialogVisible = true;
      this.title = "批注信息设置";
      this.annotationForm = {
        sheetPrintNotes: sheetPrintNotes === 1,
        sheetPrintObjectNotes: sheetPrintObjectNotes === 1,
        sheetPrintScore: sheetPrintScore === 1,
        sheetPrintTeacher: sheetPrintTeacher === 1,
        sheetPrintQuestionScore: sheetPrintQuestionScore == 1,
        statId: id,
      };
    },
    async submit() {
      // 提交批注信息设置
      this.btnLoading = true;
      this.dialogVisible = false;
      if (this.title === "批注信息设置") {
        for (let key in this.annotationForm) {
          this.$set(this.annForm, key, Number(this.annotationForm[key]));
        }
        await changestatsheetprintsetting(this.annForm);
        this.btnLoading = false;
        this.$emit("getData");
      } else if (this.title === "提示") {
        const res = await openstat({ id: this.itemId });
        this.btnLoading = false;
        this.$message({
          message: res.data.data,
          type: "success",
        });
        this.$emit("getData");
      }
    },
    routerName(name, { examId, examStatus }) {
      this.$router.push({
        name: name,
        query: {
          examId: examId,
          status: examStatus,
          routerName: this.$route.name,
        },
      });
    },
    statStart({ id }) {
      this.dialogVisible = true;
      this.title = "提示";
      this.itemId = id;
    },
    handleClose() {
      this.dialogVisible = false;
      this.btnLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.statList {
  line-height: 1;

  &-item {
    width: 100%;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    padding: 24px;
    margin-bottom: 24px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;

        .level {
          width: 50px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 14px;
          margin-right: 8px;
          color: #2474ed;
          border: 1px solid #2474ed;
        }

        .security1 {
          width: 50px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          border-radius: 4px;
          margin-right: 8px;
          color: #fff;
          background-color: #2474ed;
        }

        .security2 {
          width: 50px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          border-radius: 4px;
          margin-right: 8px;
          color: #fff;
          background-color: #f57574;
        }

        .counted {
          width: 50px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          margin-right: 8px;
          color: #2474ed;
          border-radius: 4px;
          background-color: #f2f6ff;
        }

        .counting {
          width: 50px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          margin-right: 8px;
          color: #fff;
          background-color: #ff8540;
        }

        .fail {
          width: 64px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          margin-right: 8px;
          color: #fff;
          background-color: #f43f3f;
        }

        .not_counted {
          width: 50px;
          font-size: 14px;
          border-radius: 4px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #f57574;
          margin-right: 8px;
          background-color: #ffebeb;
        }

        &-content {
          font-size: 14px;
          color: #101011;
          font-weight: bold;
          // max-width: 30%;
          box-sizing: border-box;
          padding: 5px 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 8px;
        }

        .tag {
          background-color: #edf2ff;
          box-sizing: border-box;
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          padding: 0 4px;
          color: #6f7074;
          margin-right: 8px;
        }

        .year {
          color: #56575d;
          border-radius: 4px;
          background: #f0f4ff;
          width: 85px;
          height: 24px;
          line-height: 24px;
        }

        .pulish-text {
          font-size: 14px;
          color: #5f5f68;
          margin-right: 8px;
        }

        .examType1 {
          background: #f2f6ff;
          border-radius: 4px;
          min-width: 75px;
          height: 24px;
          line-height: 24px;
          color: #2474ed;
          font-size: 14px;
        }

        .examType2 {
          background: #fff1e7;
          border-radius: 4px;
          min-width: 64px;
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          color: #ff6600;
        }

        .examType3 {
          background: #ffebeb;
          border-radius: 4px;
          min-width: 64px;
          height: 24px;
          line-height: 24px;
          color: #f57574;
          font-size: 14px;
        }

        .updateTime {
          font-size: 14px;
        }
      }

      .updateTime {
        font-size: 14px;
        color: #676b70;
      }
    }

    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px 0;

      .subject {
        color: #0a1119;
        line-height: 1.5;
      }

      i {
        font-size: 18px;
        cursor: pointer;
        color: #fd8585;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // grid-gap: 10%;
      @media screen and (max-width: 1700px) {
        align-items: flex-start;
      }

      line-height: 1;

      .content-operation {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .button {
          box-sizing: border-box;
          padding-right: 24px;
          margin-right: 24px;
          height: 14px;
          font-size: 14px;
          border-right: 2px solid #c0c0c0;

          @media screen and (max-width: 1700px) {
            margin-bottom: 20px;
          }
        }

        & :last-child {
          border: none;
        }
      }

      .item-operation {
        white-space: nowrap;
      }
    }
  }
}

::v-deep .el-dialog {
  max-width: 816px;

  .el-dialog__header {
    padding: 24px 32px 0;

    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
    }

    .el-dialog__headerbtn {
      right: 29px;
      top: 29px;
    }
  }

  .el-dialog__body {
    padding: 24px 32px;

    .el-form {
      &:last-child {
        // border-bottom: 1px solid #e0e0e0;
      }
    }

    .form-item-box {
      // border-top: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .checkbox-item {
        width: 100%;
        // padding-left: 100px;
        margin-bottom: 8px;
      }

      .el-date-editor.el-input {
        width: 100%;
      }

      .item-box-btn {
        display: flex;
        justify-content: space-between;

        p {
          color: $primary-color;
          font-size: 14px;
          margin-right: 8px;
          margin-left: 8px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .two-box {
      .el-form-item {
        width: 49%;
      }
    }

    .el-form-item {
      padding: 8px 0;
      margin-bottom: 0;
    }
  }

  .el-form-item__label {
    text-align: left;
  }
}

.startStat {
  color: #101011;
  font-size: 14px;

  &-text {
    margin-bottom: 16px;
  }

  &-warning {
    color: #ff0000;
    margin-bottom: 16px;
  }
}

.progress {
  margin-top: 16px;
}

.tip-box {
  display: flex;
  align-items: center;
  color: #101011;

  i {
    font-size: 20px;
    margin-right: 11px;
  }

  .success {
    color: #68c23a;
  }

  .fail {
    color: #ffaa5a;
  }

  div {
    line-height: 20px;
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #ededef;
  padding: 24px 24px 16px !important;
  font-size: 16px;
}

.schools {
  .filter {
    margin-bottom: 18px;
  }

  .schoolList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .school {
      margin-bottom: 18px;
      color: #2474ed;
      cursor: pointer;
    }
  }
}
</style>
