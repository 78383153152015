<template>
  <div class="rankSetting">
    <el-button type="text" @click="openModal">排名信息设置</el-button>
    <el-dialog
      title="排名信息设置"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="tips">
        <i class="el-icon-warning" style="color: #2474ed; margin-right: 8px"></i
        >注意：更改当前设置后，将清空已有成绩包
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="是否显示排名" prop="isUnionDisplayStudentRank">
          <el-radio-group
            v-model="form.isUnionDisplayStudentRank"
            @change="radioChange"
          >
            <el-radio :label="0">不显示</el-radio>
            <el-radio :label="1">显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="成绩排序方式"
          prop="schoolReportStudentRankListOrder"
        >
          <el-radio-group v-model="form.schoolReportStudentRankListOrder">
            <el-radio :label="1" :disabled="form.isUnionDisplayStudentRank == 0"
              >按排名排序</el-radio
            >
            <el-radio :label="2">按考号排序</el-radio>
            <el-radio :label="3">按姓名排序</el-radio>
            <el-radio :label="4">随机排序</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="handleOk"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { changestatstudentranksetting } from "@/core/api/academic/report";
export default {
  name: "RankSetting",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {
        isUnionDisplayStudentRank: 1,
        schoolReportStudentRankListOrder: 1,
      },
      rules: {
        isUnionDisplayStudentRank: [
          { required: true, message: "请选择是否显示排名", trigger: "change" },
        ],
        schoolReportStudentRankListOrder: [
          { required: true, message: "请选择排名方式", trigger: "change" },
        ],
      },
    };
  },
  created() {},
  methods: {
    radioChange() {
      if (this.form.isUnionDisplayStudentRank == 0) {
        this.form.schoolReportStudentRankListOrder = 2;
      } else {
        this.form.schoolReportStudentRankListOrder = 1;
      }
    },
    openModal() {
      this.form = {
        isUnionDisplayStudentRank: this.item.isUnionDisplayStudentRank,
        schoolReportStudentRankListOrder:
          this.item.schoolReportStudentRankListOrder,
      };
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            try {
              await changestatstudentranksetting({
                ...this.form,
                statId: this.item.id,
              });
              this.$message({
                type: "success",
                showClose: true,
                message: "保存成功",
              });
              this.$emit("ok", this.form);
              this.loading = false;
              this.dialogVisible = false;
            } catch {
              this.loading = false;
            }
          } catch {
            this.dialogVisible = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rankSetting {
}

.tips {
  background: #f3f8ff;
  font-style: 14px;
  letter-spacing: 1px;
  color: #0a1119;
  box-sizing: border-box;
  padding: 8px 10px;
  width: 100%;
  margin-bottom: 18px;
}
</style>
